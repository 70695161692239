.modal__error {
	.content {
		flex-grow: 1;

		span {
			font-weight: 500;
			@include px2res(font-size, 20);
		}
	}
}
