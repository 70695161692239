.modal__mission {
	@include flexcc;
	@include px2res(padding, 25);
	justify-content: space-between;

	.mission-header {
		@include flexrc;
		justify-content: space-between;
		width: 100%;
		position: relative;

		> span {
			color: #000000;
			@include px2res(font-size, 30);
			font-weight: bold;
		}

		.logo {
			@include px2res(width, 210);
			@include px2res(height, 46);
			@include backgroundImageCdn("logo_ffxjkt_small.png");
		}
	}

	.content {
		@include px2res(height, 484);
		width: 100%;
		@include flexcc;
		justify-content: space-between;

		> span {
			align-self: flex-start;
			@include px2res(font-size, 18);
		}

		ul {
			flex-grow: 1;
			width: 100%;
			overflow-y: auto;
			@include px2res(margin-top, 20);

			&::-webkit-scrollbar {
				@include px2res(width, 6);
			}
			&::-webkit-scrollbar-thumb {
				background-color: #000000;
			}
			&::-webkit-scrollbar-track {
				background-color: #f28482;
			}

			li {
				@include px2res(width, 896);
				@include px2res(height, 96);
				border: px2vh(4) solid #f28482;
				@include px2res(border-radius, 10);
				@include flexrc;
				justify-content: space-between;
				padding: 0% 2.5%;

				&:not(:last-child) {
					@include px2res(margin-bottom, 10);
				}

				> span {
					@include px2res(font-size, 24);
					font-weight: 500;
				}

				.btn-claim {
					@include px2res(width, 112);
					@include px2res(height, 50);
					@include flexrc;
					@include px2res(font-size, 20);
					@include px2res(border-radius, 8);
					border: px2vh(2) solid #f28482;
					background: linear-gradient(180deg, #f5cac3 0%, #fff 100%);
					box-shadow: 0px px2vh(10) px2vh(10) #00000066;
					font-weight: bold;
					text-transform: capitalize;

					.token-ico {
						@include px2res(width, 24);
						@include px2res(height, 24);
						@include backgroundImageCdn("ico_token_small.png");
						@include px2res(margin-right, 5);
					}

					&.disabled {
						box-shadow: unset;
						filter: unset;
						background: #b4b4b4 0% 0% no-repeat padding-box;
						border: px2vh(2) solid #c9c9c9;
					}
				}
			}
		}
	}
}
