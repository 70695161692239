.modal__info {
	@include flexcc;
	@include px2res(padding, 25);
	justify-content: space-between;

	.info-header {
		@include flexrc;
		justify-content: space-between;
		width: 100%;
		position: relative;

		> span {
			color: #000000;
			@include px2res(font-size, 30);
			font-weight: bold;
		}

		.logo {
			@include px2res(width, 210);
			@include px2res(height, 46);
			@include backgroundImageCdn("logo_ffxjkt_small.png");
		}
	}

	.content {
		// @include px2res(height, 500);
		flex-grow: 1;
		width: 100%;
		padding-right: 4%;
		overflow-y: scroll;

		p,
		li,
		ul,
		span {
			color: #000000;
			font-weight: normal;
			@include px2res(font-size, 18);
			@include px2res(line-height, 22);
		}

		ul,
		ol {
			@include px2res(padding-inline-start, 40);
		}

		ol {
			list-style-type: decimal;
		}
		ul {
			list-style-type: circle;
		}
	}
}
