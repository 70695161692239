.modal__history {
	@include flexcc;
	@include px2res(padding, 25);
	justify-content: space-between;

	.history-header {
		@include flexrc;
		justify-content: space-between;
		width: 100%;
		position: relative;

		> span {
			color: #000000;
			@include px2res(font-size, 30);
			font-weight: bold;
		}

		.tab {
			@include px2res(min-width, 288);
			width: fit-content;
			@include px2res(height, 33);
			@include px2res(border-radius, 5);
			@include flexrc;
			justify-content: space-between;
			background: #c9c9c9 0% 0% no-repeat padding-box;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			box-sizing: content-box;
			padding: px2vh(2) px2vh(5);

			li {
				@include px2res(height, 27);
				@include flexrc;
				@include px2res(font-size, 14);
				padding: 0 px2vh(15);
				text-transform: capitalize;
				cursor: pointer;

				&.active {
					box-shadow: 0px px2vh(3) px2vh(3) #00000029;
					background: linear-gradient(180deg, #fe0000 0%, #fe0098 100%);
					@include px2res(border-radius, 5);
					font-weight: bold;
					color: #fff;
				}

				&:not(:last-child) {
					border-right: px2vh(1) solid #b4b4b4;
				}
			}
		}

		.logo {
			@include px2res(width, 210);
			@include px2res(height, 46);
			@include backgroundImageCdn("logo_ffxjkt_small.png");
		}
	}

	.content {
		@include px2res(height, 500);
		width: 100%;

		.col-name {
			width: 100%;
			@include flexrc;
			justify-content: space-between;
			@include px2res(height, 48);
			padding-right: 4%;

			> span {
				@include px2res(font-size, 18);
				font-weight: bold;
				color: #000000;

				&:first-child {
					width: 15%;
				}
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					width: 27%;
				}
			}
		}

		.history-list {
			width: 100%;
			height: 90%;
			overflow-y: auto;
			padding-right: 4%;

			&::-webkit-scrollbar {
				@include px2res(width, 6);
			}
			&::-webkit-scrollbar-thumb {
				background-color: #000;
			}
			&::-webkit-scrollbar-track {
				background-color: #f28482;
			}

			li {
				@include flexrc;
				@include px2res(min-height, 70);
				justify-content: space-between;
				width: 100%;
				height: fit-content;
				border-top: px2vh(1) solid #707070;

				&:last-child {
					border-bottom: px2vh(1) solid #707070;
				}

				> div {
					&:first-child {
						width: 15%;
					}
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						width: 27%;
					}

					font-weight: bold;
					@include px2res(font-size, 18);

					&.cost {
						@include flexrc;
						justify-content: flex-start;

						> div {
							margin-right: 5%;

							.token-ico {
								@include px2res(height, 24);
								@include px2res(width, 24);
								@include backgroundImageCdn("ico_token_small.png");
								@include px2res(margin-right, 6);
							}
							.stamp-ico {
								@include px2res(height, 30);
								@include px2res(width, 30);
								background: url("https://via.placeholder.com/30") center/contain no-repeat;
								@include px2res(margin-right, 6);
							}

							@include px2res(height, 35);
							@include px2res(min-width, 65);
							@include px2res(border-radius, 7);
							@include flexrc;
							background: #c9c9c9 0% 0% no-repeat padding-box;
							width: fit-content;

							&.claim {
								font-weight: 500;
								@include px2res(font-size, 14);
								@include px2res(padding-left, 15);
								@include px2res(padding-right, 15);
							}
						}
					}

					&.result {
						@include flexrc;
						justify-content: flex-start;
						box-sizing: content-box;
						padding: px2vh(10) 0;

						> div {
							@include px2res(margin-right, 10);

							.token-ico {
								@include px2res(height, 24);
								@include px2res(width, 24);
								@include backgroundImageCdn("ico_token_small.png");
								@include px2res(margin-right, 6);
							}
							.stamp-ico {
								@include px2res(height, 30);
								@include px2res(width, 30);
								@include px2res(margin-right, 6);
								background: url("https://via.placeholder.com/30") center/contain no-repeat;
							}

							.item-img {
								@include px2res(height, 60);
								@include px2res(width, 60);
								background: url("https://via.placeholder.com/50") center/90% no-repeat;
							}

							@include px2res(min-height, 35);
							@include px2res(min-width, 73);
							@include px2res(border-radius, 7);
							@include flexrc;
							background: #00ffd1 0% 0% no-repeat padding-box;
							width: fit-content;
							height: fit-content;

							&.share {
								@include px2res(width, 207);
								@include px2res(height, 35);
								@include px2res(border-radius, 7);
								background: #ffffff 0% 0% no-repeat padding-box;
								position: relative;
								font-weight: 500;
								color: #000000;
								@include px2res(font-size, 14);

								&::after {
									content: "";
									position: absolute;
									@include px2res(width, 12);
									@include px2res(height, 15);
									@include backgroundImageCdn("ico_share.png");
									right: 5%;
								}
							}
						}

						> span {
							font-weight: 500;
							@include px2res(font-size, 14);
							color: #000000;
						}
					}
				}
			}
		}
	}
}
