.customOverlay {
	padding: 0 !important;
	top: 0 !important;
	width: 100%;
	height: 100%;
	background: #000000 0% 0% no-repeat padding-box;
	backdrop-filter: blur(2px);

	&.video {
		background: none;
	}

	.customModal {
		@import "./intro";
		@import "./confirmation";
		@import "./congratulations";
		@import "./history";
		@import "./info";
		@import "./mission";
		@import "./share";
		@import "./unlocked";
		@import "./videoshare";
		@import "./shared";
		@import "./error";

		max-width: unset;
		padding: 0;
		background: none;
		background: radial-gradient(139.58% 50% at 50% 50%, #fff 0%, #f3f3f3 74.9%, #f2dae7 100%);
		border: px2vh(4) solid #e8e8e8;
		@include px2res(border-radius, 20);

		&.small {
			@include px2res(width, 600);
			@include px2res(height, 350);
			padding: 1.5% 0;
			--animate-duration: 0.3s;

			.content {
				@include px2res(height, 150);
				@include flexcc;
				width: 100%;
			}
		}

		&.medium {
			@include px2res(width, 664);
			@include px2res(height, 462);
			padding: 1% 0;
		}

		&.big {
			@include px2res(width, 1000);
			@include px2res(height, 600);

			.btn-close {
				@include px2res(width, 30);
				@include px2res(height, 28);
				@include backgroundImageCdn("ico_close.png");
				position: absolute;
				right: -5.5%;
				top: 3.5%;
			}
		}

		> div {
			height: 100%;
			width: 100%;
			@include flexcc;
			justify-content: space-between;

			.title {
				@include flexcc;

				.main {
					@include px2res(font-size, 30);
					font-weight: bold;
					color: #000000;
					text-transform: uppercase;
				}

				.sub {
					@include px2res(margin-top, 5);
					font-weight: 500;
					color: #000000;
					@include px2res(font-size, 20);
				}
			}

			.button-wrapper {
				@include flexrc;

				.btn {
					@include px2res(width, 162);
					@include px2res(height, 75);
					@include px2res(border-radius, 8);
					box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
					font-weight: bold;
					@include px2res(font-size, 24);
					@include flexrc;
					margin: 0 px2vh(15);

					&-back {
						background: #707070 0% 0% no-repeat padding-box;
						border: px2vh(2) solid #b4b4b4;
						color: #c9c9c9;
					}
					&-confirm {
						background: linear-gradient(180deg, #f5cac3 0%, #fff 100%);
						border: px2vh(2) solid #f28482;
						color: #000000;
					}
					&-watch {
						background: #f6e420 0% 0% no-repeat padding-box;
						border: px2vh(2) solid #f2ff3d;
						color: #000000;

						.arrow {
							@include px2res(margin-left, 15);
							@include px2res(width, 25);
							@include px2res(height, 25);
							@include backgroundImageCdn("ico_arrow_purple_right.png");
						}
					}
				}
			}
		}
	}
}
