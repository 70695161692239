$url: "https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/jkt48/images/";

.page__video {
	@include flexcc;
	justify-content: space-between;
	@include px2res(padding-top, 25);
	background: url($url + "img_jkt_video_left.png") center left/contain no-repeat,
		url($url + "img_jkt_video_right.png") center right/contain no-repeat;

	.video-main {
		@include px2res(width, 707);
		@include px2res(height, 400);
		@include px2res(border-radius, 20);
		border: px2vh(4) solid #f6e420;

		.player {
			clip-path: inset(0% 0% 0% 0% round px2vh(15));

			.playicon {
				@include px2res(width, 87);
				@include px2res(height, 94);
				@include backgroundImageCdn("ico_play_vid.png");
				z-index: 1;
			}

			.react-player__preview {
				position: relative;

				&::before {
					width: 100%;
					height: 100%;
					position: absolute;
					content: "";
					background-color: #00000080;
				}
			}
		}

		.thumbnail {
			width: 100%;
			height: 100%;
			background: url("https://via.placeholder.com/707x400") center/contain no-repeat;
			position: relative;

			&.locked {
				@include flexcc;
				@include px2res(border-radius, 20);

				&::before {
					content: "";
					width: 100%;
					height: 100%;
					position: absolute;
					background: #000000 0% 0% no-repeat padding-box;
					@include px2res(border-radius, 20);
					opacity: 0.5;
				}

				.lock {
					@include px2res(width, 50);
					@include px2res(height, 50);
					@include backgroundImageCdn("ico_lock.png");
					background-size: contain;
					position: relative;
				}
			}
		}
	}

	.note {
		@include px2res(min-width, 624);
		@include px2res(height, 41);
		@include backgroundImageCdn("img_note.png");
		@include flexcc;
		width: fit-content;
		background-size: 100% 100%;
		padding: 0 px2vh(20);
		box-sizing: content-box;

		span {
			@include px2res(font-size, 14);
			color: #ffffff;
			font-weight: normal;
		}
	}

	.video-list {
		position: relative;
		display: grid;
		grid-auto-flow: column;
		align-content: flex-start;
		justify-content: center;
		@include px2res(grid-gap, 12);
		@include px2res(width, 1222);
		@include px2res(height, 150);

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			width: 100%;
			@include px2res(height, 70);
			border: px2vh(1) solid #83c6e9;
			border-radius: px2vh(10) px2vh(10) 0px 0px;
			opacity: 1;
			backdrop-filter: blur(px2vh(30));
			-webkit-backdrop-filter: blur(px2vh(30));
		}

		li {
			@include px2res(width, 178);
			@include px2res(height, 100);
			@include px2res(border-radius, 10);
			position: relative;
			top: 6%;
			cursor: pointer;

			&.active {
				&::before {
					content: "";
					width: 100%;
					height: 100%;
					position: absolute;
					background: #f6e420 0% 0% no-repeat padding-box;
					filter: blur(30px);
				}
			}

			.thumbnail {
				width: 100%;
				height: 100%;
				background: url("https://via.placeholder.com/178x100") center/contain no-repeat;
				position: relative;
				@include px2res(border-radius, 10);
				@include flexcc;

				&::before {
					content: "";
					width: 100%;
					height: 100%;
					position: absolute;
					@include px2res(border-radius, 10);
				}

				&.locked {
					&::before {
						opacity: 0.5;
						background: #000000 0% 0% no-repeat padding-box;
					}

					.lock {
						@include px2res(width, 21);
						@include px2res(height, 25);
						@include backgroundImageCdn("ico_lock.png");
						position: relative;
					}
				}

				&.playable {
					.play {
						@include px2res(width, 21);
						@include px2res(height, 25);
						@include backgroundImageCdn("ico_play_vid_small.png");
						position: relative;
					}
				}
			}

			.video-name {
				position: absolute;
				top: 105%;
				@include px2res(font-size, 14);
				font-weight: normal;
				color: #ffffff;
				text-shadow: 0px px2vh(3) px2vh(6) #00000029;
				opacity: 0.9;
				text-align: center;
				width: 80%;
				margin: auto;
				left: 0;
				right: 0;
			}
		}
	}
}
