.modal__share {
	padding: 2% 3%;

	.content {
		@include flexcc;
		@include px2res(height, 330);
		justify-content: space-between;

		ul {
			@include flexrc;
			@include px2res(width, 700);
			@include px2res(height, 240);
			flex-wrap: wrap;

			li {
				@include px2res(width, 120);
				@include px2res(height, 120);
				margin: 0 px2vh(5);
				position: relative;

				&.active::before {
					content: "";
					position: absolute;
					width: 110%;
					height: 110%;
					left: -25%;
					right: -25%;
					top: 0;
					bottom: 0;
					margin: auto;
					background: #00ffd1 0% 0% no-repeat padding-box;
					border-radius: 50%;
				}

				.token-count {
					@include px2res(width, 35);
					@include px2res(height, 35);
					@include flexrc;
					position: absolute;
					right: 0;
					top: 0;
					background: #fe0000 0% 0% no-repeat padding-box;
					box-shadow: 0px px2vh(3) px2vh(6) #00000029;
					border-radius: 50%;
					color: #ffffff;
					font-weight: bold;
					@include px2res(font-size, 16);
					z-index: 1;
				}

				.token-img {
					background: url("https://via.placeholder.com/120") center/contain no-repeat;
					width: 100%;
					height: 100%;
					position: relative;
				}
			}
		}

		.btn-confirm {
			@include px2res(width, 196);
			@include px2res(height, 75);
			@include px2res(border-radius, 8);
			@include flexrc;
			@include px2res(font-size, 24);
			background: linear-gradient(180deg, #f5cac3 0%, #fff 100%);
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
			border: px2vh(2) solid #f28482;
			font-weight: bold;
			color: #000000;
		}
	}

	.claim-code {
		@include px2res(height, 130);
		width: 100%;
		border-top: px2vh(1) solid #707070;

		form {
			width: 100%;
			height: 100%;
			@include flexcc;
			justify-content: space-between;
			padding-top: 1.5%;

			> label {
				font-weight: 500;
				@include px2res(font-size, 20);
			}

			.typebox {
				@include px2res(width, 740);
				@include px2res(height, 60);
				position: relative;

				> input[type="text"] {
					@include px2res(border-radius, 31);
					width: 100%;
					height: 100%;
					outline: none;
					border: none;
					text-align: center;
					@include px2res(font-size, 30);
					font-weight: bold;

					&::placeholder {
						color: #c9c9c9;
					}
				}

				> button[type="submit"] {
					@include px2res(border-radius, 31);
					@include px2res(width, 130);
					height: 100%;
					right: 0;
					top: 0;
					background: #000000 0% 0% no-repeat padding-box;
					position: absolute;
					@include flexrc;
					font-weight: bold;
					color: #ffffff;
					@include px2res(font-size, 24);

					.arrow {
						@include px2res(width, 25);
						@include px2res(height, 25);
						@include backgroundImageCdn("ico_arrow_purple_right.png");
						@include px2res(margin-left, 7);
					}
				}
			}

			.error-msg {
				@include px2res(font-size, 12);
				@include px2res(height, 12);
				color: #fe0000;
				font-weight: bold;
			}
		}
	}
}
