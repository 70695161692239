@import "/src/assets/scss/mixins";
@import "/src/assets/scss/variable";

#supportBtn {
	text-decoration: none;
	color: white;
	padding: px2vh(10) px2vh(15);
	border-radius: px2vh(10);
	background-color: #e61a23;

	span {
		filter: drop-shadow(0 0 0.2rem black);
	}
}
