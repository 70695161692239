.header {
	width: 100%;
	@include px2res(height, 100);
	@include flexrc;
	background: transparent linear-gradient(180deg, #ffffff 0%, #ffffff 84%, #aafff6 100%) 0% 0% no-repeat
		padding-box;
	justify-content: space-between;

	&-side {
		height: 100%;
		@include px2res(width, 242);
		@include flexcc;
		justify-content: center;
		align-items: flex-start;

		.btn-audio {
			@include px2res(margin-left, 38);
			@include flexrc;
			@include px2res(font-size, 20);
			background: none;
			border: none;
			font-weight: bold;
			color: #000000;

			.icon {
				@include px2res(width, 30);
				@include px2res(height, 30);
				@include px2res(margin-right, 10);

				&.on {
					@include backgroundImageCdn("ico_music_on.png");
				}
				&.off {
					@include backgroundImageCdn("ico_music_off.png");
				}
			}
		}

		&.player {
			@include px2res(padding-left, 50);

			.token {
				@include flexrc;

				&-ico {
					@include px2res(width, 24);
					@include px2res(height, 24);
					@include px2res(margin-right, 5);
					@include backgroundImageCdn("ico_token_small.png");
				}

				span {
					font-weight: bold;
					color: #000000;
					@include px2res(font-size, 30);
				}
			}

			.uid {
				font-weight: normal;
				color: #000000;
				opacity: 0.5;
				@include px2res(font-size, 17);
			}
		}
	}

	.navigation {
		@include px2res(width, 850);
		height: 100%;
		display: flex;
		margin: auto;
		justify-content: center;

		a {
			@include px2res(width, 300);
			height: 100%;
			@include flexrc;
			text-decoration: none;
			position: relative;
			text-align: center;

			&.active {
				background: #fe0000 0% 0% no-repeat padding-box;

				span {
					color: #ffffff;
					font-weight: bold;
					@include px2res(font-size, 30);
				}
				.dot {
					display: none;
				}
			}

			span {
				@include px2res(font-size, 24);
				font-weight: 500;
				color: #707070;
				text-transform: capitalize;
			}

			.dot {
				position: absolute;
				@include px2res(width, 12);
				@include px2res(height, 12);
				border-radius: 50%;
				background-color: #fe0000;
				top: 0;
				bottom: 0;
				right: 17%;
				margin: auto;
			}
		}
	}
}
