.modal__video {
	.content {
		height: 75% !important;
		flex-direction: row !important;
		@include px2res(grid-gap, 20);
		flex-wrap: wrap;
		padding: 0 15%;

		> button {
			@include px2res(height, 100);
			@include px2res(width, 100);
			@include px2res(border-radius, 20);
			box-shadow: 0px px2vh(10) px2vh(6) #00000029;

			&[aria-label="facebook"] {
				@include backgroundImageCdn("ico_social_fb.png");
			}
			&[aria-label="twitter"] {
				@include backgroundImageCdn("ico_social_tw.png");
			}
			&[aria-label="vk"] {
				@include backgroundImageCdn("ico_social_vk.png");
			}
			&[aria-label="whatsapp"] {
				@include backgroundImageCdn("ico_social_wa.png");
			}
			&[aria-label="facebookmessenger"] {
				@include backgroundImageCdn("ico_social_fbms.png");
			}
		}
	}
}
