@import "variable";
@import "font";
@import "reset";
@import "mixins";
@import "functions";
@import "lang";

@import "pages/home";
@import "pages/tokens";
@import "pages/exchange";
@import "pages/video";

@import "partials/header";
@import "partials/historyinfo";
@import "partials/modal/index";

* {
	font-family: "GFF Latin" !important;
}

.content-container {
	width: 100vw;
	height: 100vh;
	position: relative;
	@include backgroundImageCdn("bg.jpg");
	background-size: cover;

	&.disabled-button {
		* {
			pointer-events: none;
		}
	}

	> div:last-child {
		> div:first-child {
			@include px2res(height, 650);
		}
	}
}

.__react_component_tooltip.tooltip {
	@include px2res(font-size, 12);
}

.btn {
	outline: none;
	font-weight: bold;
	cursor: pointer;
	border: none;
	background: none;

	&.disabled {
		filter: grayscale(1);
		pointer-events: none;
	}
}

.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal {
	animation-fill-mode: forwards !important;
}
