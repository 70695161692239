.page__tokens {
	@include flexrc;
	justify-content: space-between;
	@include px2res(padding-right, 50);

	.overlays-left {
		@include px2res(width, 460);
		@include px2res(height, 615);
		align-self: flex-start;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			@include px2res(width, 440);
			@include px2res(height, 580);
			bottom: 0;
			left: 0;
			background: transparent radial-gradient(closest-side at 50% 50%, #ffffff 0%, #f3f3f3 69%, #d1d1d1 100%)
				0% 0% no-repeat padding-box;
			border: px2vh(4) solid #e8e8e8;
			border-radius: 0px px2vh(20) px2vh(20) 0px;
			opacity: 0.35;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include backgroundImageCdn("img_grandprizes.png");
		}
	}

	.center-content {
		@include px2res(width, 384);
		height: 100%;
		position: relative;

		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			10% {
				opacity: 0.5;
			}
			20% {
				opacity: 1;
			}
		}

		// .char {
		// 	animation: fadeIn 1s ease-in-out infinite 0.9s;
		// 	opacity: 0;
		// }

		.char {
			@include px2res(width, 348);
			@include px2res(height, 650);
			background-size: contain !important;

			&-1 {
				@include backgroundImageCdn("img_rotate_1.png");
			}
			&-2 {
				@include backgroundImageCdn("img_rotate_2.png");
			}
			&-3 {
				@include backgroundImageCdn("img_rotate_3.png");
			}
			&-4 {
				@include backgroundImageCdn("img_rotate_4.png");
			}
			&-5 {
				@include backgroundImageCdn("img_rotate_5.png");
			}
			&-6 {
				@include backgroundImageCdn("img_rotate_6.png");
			}
			&-7 {
				@include backgroundImageCdn("img_rotate_7.png");
			}
			&-8 {
				@include backgroundImageCdn("img_rotate_8.png");
			}
			&-9 {
				@include backgroundImageCdn("img_rotate_9.png");
			}
		}

		.action {
			position: absolute;
			@include px2res(bottom, 35);
			width: 100%;
			text-align: center;

			.btn-spin {
				@include px2res(width, 300);
				@include px2res(height, 75);
				background: linear-gradient(180deg, #f5cac3 0%, #fff 100%);
				box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
				border: px2vh(2) solid #f28482;
				@include px2res(border-radius, 8);
				font-weight: bold;
				@include px2res(font-size, 30);
				text-transform: capitalize;
				@include flexrc;
				margin: auto;
				color: #000000;

				.token-ico {
					@include px2res(width, 50);
					@include px2res(height, 50);
					@include backgroundImageCdn("ico_token_medium.png");
				}

				span {
					@include px2res(font-size, 40);
				}
			}

			.btn-mission {
				border: none;
				background: none;
				text-decoration: underline;
				color: #000;
				font-weight: 500;
				@include px2res(font-size, 22);
				@include px2res(margin-top, 8);
			}
		}
	}

	.token-container {
		@include px2res(width, 440);
		@include px2res(height, 580);
		@include backgroundImageCdn("img_frame_tokens.png");
		position: relative;

		.stamps {
			width: 100%;
			height: 100%;
			@include px2res(padding, 20);
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: space-between;

			> div {
				@include px2res(width, 120);
				@include px2res(height, 120);
				background-position: center;
				background-size: contain;
				position: relative;
				background-repeat: no-repeat;

				.tooltip {
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;

					.dot {
						@include px2res(width, 10);
						@include px2res(height, 10);
						background-color: #fe0000;
						border-radius: 50%;
						position: absolute;
						right: 10%;
						top: 10%;
					}
				}

				.count {
					@include px2res(width, 35);
					@include px2res(height, 35);
					@include flexrc;
					position: absolute;
					right: -5%;
					top: -6%;
					background: #fe0000 0% 0% no-repeat padding-box;
					box-shadow: 0px px2vh(3) px2vh(6) #00000029;
					border-radius: 50%;
					color: #ffffff;
					font-weight: bold;
					@include px2res(font-size, 16);
				}
			}
		}

		.action {
			@include px2res(width, 226);
			@include px2res(height, 120);
			@include px2res(right, 35);
			@include px2res(bottom, 25);
			@include flexcc;
			position: absolute;
			justify-content: space-between;

			> span {
				font-weight: 500;
				@include px2res(font-size, 14);
				line-height: 1;
				text-align: center;
			}

			.btn-share {
				background: linear-gradient(180deg, #f5cac3 0%, #fff 100%);
				box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
				border: px2vh(2) solid #f28482;
				@include px2res(border-radius, 8);
				@include px2res(width, 226);
				@include px2res(height, 75);
				@include flexrc;
				@include px2res(font-size, 24);
				font-weight: bold;
				color: #000000;

				.arrow {
					@include px2res(margin-left, 5);
					@include px2res(width, 25);
					@include px2res(height, 25);
					@include backgroundImageCdn("ico_arrow_purple_right.png");
				}
			}
		}
	}
}
