$transparent: rgba(0, 0, 0, 0);
$background: white;
$shadow: black;

.page__exchange {
	@include flexcc;
	align-items: center;
	position: relative;

	> div:first-child {
		@include px2res(height, 600);
		@include px2res(padding-left, 65);
		max-width: 100%;
		overflow-x: scroll;

		&::-webkit-scrollbar {
			display: none;
		}

		ul {
			height: 100%;
			display: grid;
			grid-auto-flow: column;
			justify-content: flex-start;
			@include px2res(grid-gap, 12);

			li {
				background: url("https://via.placeholder.com/220x600") center/contain no-repeat;
				height: 100%;
				flex-shrink: 0;
				@include px2res(width, 220);
				display: grid;
				grid-auto-flow: row;
				align-content: flex-end;
				justify-content: center;
				@include px2res(padding-bottom, 15);
				@include px2res(grid-gap, 5);

				.prize-box {
					@include px2res(width, 190);
					@include px2res(min-height, 120);
					height: fit-content;
					background: #ffffff99 0% 0% no-repeat padding-box;
					border: px2vh(1) solid #ffffff;
					border-radius: px2vh(10);
					position: relative;

					.name {
						width: 100%;
						height: fit-content;
						@include px2res(min-height, 20);
						background: #ffffff 0% 0% no-repeat padding-box;
						border-radius: 10px 10px 0px 0px;
						@include flexcc;

						span {
							text-align: center;
							font-weight: bold;
							@include px2res(font-size, 10);
						}
					}

					.details {
						width: 100%;
						@include px2res(height, 100);

						.image {
							@include px2res(width, 75);
							@include px2res(height, 75);
							background: url("https://via.placeholder.com/100") center/contain no-repeat;
							position: absolute;
							@include px2res(left, 5);
							@include px2res(bottom, 10);
							// inset: 0;
							// margin: auto 0;
						}

						.claim {
							@include px2res(width, 100);
							@include flexcc;
							justify-content: space-between;
							margin-left: auto;
							@include px2res(margin-right, 5);
							height: 100%;
							padding: 5% 0;

							.cost {
								@include px2res(height, 40);
								width: 100%;
								@include flexrc;

								.material {
									width: 50%;
									text-align: center;

									&.multiples {
										border-left: px2vh(3) solid #70707030;
									}

									&-img {
										@include px2res(width, 30);
										@include px2res(height, 30);
										background: url("https://via.placeholder.com/30") center/contain no-repeat;
										margin: auto;
									}

									&-qty {
										width: 100%;

										> span {
											display: block;
											font-weight: bold;
											@include px2res(font-size, 12);
										}
									}
								}
							}

							.btn-claim {
								@include px2res(height, 34);
								width: 100%;
								background: #707070 0% 0% no-repeat padding-box;
								border: px2vh(2) solid #b4b4b4;
								@include px2res(border-radius, 8);
								@include flexcc;
								text-transform: capitalize;
								font-weight: bold;
								@include px2res(font-size, 16);
								color: #c9c9c9;
								pointer-events: none;
							}
						}
					}

					&.active {
						background: #f2ff3d99 0% 0% no-repeat padding-box;

						.name {
							background: #f2ff3d 0% 0% no-repeat padding-box;
						}

						.details .claim .btn-claim {
							border: px2vh(2) solid #f2ff3d;
							background: #f6e420 0% 0% no-repeat padding-box;
							color: #000000;
							pointer-events: unset;
						}
					}
				}
			}
		}
	}

	.arrow {
		position: absolute;
		background: transparent linear-gradient(90deg, #01060a00 0%, #01060a 100%) 0% 0% no-repeat padding-box;
		@include px2res(width, 91);
		height: 100%;
		transition: opacity 0.3s ease-in-out;
		@include flexcc;
		align-items: flex-end;
		right: 0;

		&.show {
			opacity: 1;
			display: flex;
		}
		&.hidden {
			opacity: 0;
			display: none;
		}

		> div {
			@include px2res(width, 24);
			@include px2res(height, 24);
			@include backgroundImageCdn("ico_arrow_yellow_right.png");
			@include px2res(margin-right, 10);
			animation: move 2s infinite ease-in-out;

			@keyframes move {
				0% {
					transform: translateX(0);
				}
				50% {
					transform: translateX(5px);
				}
				100% {
					transform: translateX(0);
				}
			}
		}
	}
}
