.container {
	@include px2res(width, 56);
	@include px2res(height, 120);
	@include flexcc;
	@include px2res(top, 130);
	position: fixed;
	z-index: 999;

	.btn {
		height: 50%;
		width: 100%;
		border: px2vh(2) solid #e2e2e2;
		@include flexcc;

		.icon {
			@include px2res(width, 30);
			@include px2res(height, 30);
		}

		&-info {
			border-radius: 0 px2vh(20) 0 0;
			background: transparent linear-gradient(180deg, #ffffff 0%, #ffffff 51%, #c9c9c9 100%) 0% 0% no-repeat padding-box;

			.icon {
				@include backgroundImageCdn("ico_info.png");
			}
		}

		&-history {
			border-radius: 0 0 px2vh(20) 0;
			background: transparent linear-gradient(0deg, #ffffff 0%, #ffffff 51%, #c9c9c9 100%) 0% 0% no-repeat padding-box;

			.icon {
				@include backgroundImageCdn("ico_history.png");
			}
		}
	}
}
