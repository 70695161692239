.modal__confirmation {
	.content {
		font-weight: bold;
		@include px2res(font-size, 30);
		color: #000000;

		.img {
			@include px2res(width, 100);
			@include px2res(height, 100);
			@include px2res(margin-bottom, 5);
			// background: #c9c9c9 0% 0% no-repeat padding-box;
			// border-radius: 50%;
			position: relative;

			> div {
				width: 100%;
				height: 100%;
				background: url("https://via.placeholder.com/100") center/90% no-repeat;
			}
		}

		.name {
			font-weight: 500;
			@include px2res(font-size, 20);
		}
	}
}
