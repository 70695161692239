.modal__shared {
	.content {
		.code {
			@include px2res(width, 385);
			@include px2res(height, 36);
			@include flexrc;
			justify-content: space-between;

			.label {
				@include px2res(width, 300);
				height: 100%;
				background: #ffffff 0% 0% no-repeat padding-box;
				border: px2vh(1) solid #707070;
				@include flexcc;
				@include px2res(font-size, 20);
				font-weight: 500;
			}

			.btn-copy {
				@include px2res(width, 80);
				height: 100%;
				background: #000000 0% 0% no-repeat padding-box;
				@include px2res(font-size, 20);
				color: #ffffff;
				font-weight: normal;
			}
		}

		.stamp {
			@include px2res(margin-top, 50);
			@include px2res(height, 150);
			@include flexcc;

			.img {
				@include px2res(height, 120);
				@include px2res(width, 120);
				background: url("https://via.placeholder.com/120") center/contain no-repeat;
			}

			.name {
				@include px2res(font-size, 20);
				font-weight: 500;
				color: #000000;
				@include px2res(margin-top, 10);
			}
		}
	}
}
