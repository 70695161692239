.modal__congratulations {
	.content {
		font-weight: bold;
		@include px2res(font-size, 30);
		color: #000000;

		.img {
			@include px2res(width, 120);
			@include px2res(height, 120);
			@include px2res(margin-bottom, 5);

			&.exchange {
				// background: #00ff6f 0% 0% no-repeat padding-box;
				// border-radius: 50%;

				> div {
					background-size: 90%;
				}
			}

			> div {
				width: 100%;
				height: 100%;
				background: url("https://via.placeholder.com/120") center/contain no-repeat;
			}
		}

		.name {
			font-weight: 500;
			@include px2res(font-size, 20);
		}

		.token {
			@include flexrc;
			@include px2res(font-size, 40);
			color: #000000;
			font-weight: bold;

			&-img {
				@include px2res(width, 50);
				@include px2res(height, 50);
				@include backgroundImageCdn("ico_token_medium.png");
				background-size: contain;
			}
		}
	}
}
