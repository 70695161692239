.modal__unlocked {
	.content {
		.img {
			@include px2res(width, 178);
			@include px2res(height, 100);
			background: url("https://via.placeholder.com/178x100") center/contain no-repeat;
			@include px2res(margin-bottom, 5);
			@include px2res(border-radius, 10);
		}

		.name {
			color: #000000;
			font-weight: 500;
			@include px2res(font-size, 20);
		}
	}

	a {
		text-decoration: none;
	}
}
