$cdn: "https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ID/jkt48/images/";

.home {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	.overlays {
		position: absolute;
		bottom: 0;
		@include px2res(width, 330);
		@include px2res(height, 660);
		z-index: 1;

		&-left {
			@include backgroundImageCdn("img_jkt_left.png");
			left: 0;
		}
		&-right {
			@include backgroundImageCdn("img_jkt_right.png");
			right: 0;
		}
	}

	.event-title {
		@include backgroundImageCdn("logo_ffxjkt.png");
		@include px2res(width, 251);
		@include px2res(height, 55);
	}

	.slick-slider {
		transform: translateY(px2vh(-25));
		width: fit-content;
		z-index: 999;

		.slick-list {
			@include px2res(width, 737);
			@include px2res(height, 400);
		}

		.slick-slide {
			> div {
				margin: 0 px2vh(15);
			}
		}

		.slick-arrow {
			@include px2res(width, 50);
			@include px2res(height, 50);
			background: url($cdn + "ico_arrow_yellow_right.png") center/50% no-repeat,
				url($cdn + "blue-light.png") center/150% no-repeat;

			&::before {
				content: unset;
			}

			&.slick-prev {
				@include px2res(left, -50);
				transform: rotate(180deg);
			}
			&.slick-next {
				@include px2res(right, -50);
			}
		}

		.slick-disabled {
			background: none;

			&::before {
				content: unset;
			}
		}

		.video-frame {
			@include px2res(width, 737);
			@include px2res(height, 400);
			@include px2res(border-radius, 20);
			border: px2vh(4) solid #f6e420;

			.player {
				clip-path: inset(0% 0% 0% 0% round px2vh(15));

				.playicon {
					@include px2res(width, 87);
					@include px2res(height, 94);
					@include backgroundImageCdn("ico_play_vid.png");
					z-index: 1;
				}

				.react-player__preview {
					position: relative;

					&::before {
						width: 100%;
						height: 100%;
						position: absolute;
						content: "";
						background-color: #00000080;
					}
				}
			}
		}
	}

	.btn-share {
		@include px2res(width, 300);
		@include px2res(height, 75);
		background: #f6e420 0% 0% no-repeat padding-box;
		box-shadow: 0px px2vh(10) px2vh(10) #00000066;
		border: px2vh(2) solid #f2ff3d;
		@include px2res(border-radius, 38);
		position: relative;
		text-transform: capitalize;
		@include px2res(font-size, 30);
		color: #000000;

		&::after {
			position: absolute;
			@include px2res(width, 25);
			@include px2res(height, 25);
			@include backgroundImageCdn("ico_arrow_purple_right.png");
			content: "";
			right: 10%;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
}
